<template>
  <aside class="site-sidebar" :class="'site-sidebar--' + sidebarLayoutSkin">
    <div class="site-sidebar__inner">
	  <div class="site-bar-title" style="padding-left: 0; text-align: center;" v-if="!sidebarFold">{{menuList[menuIndex].name}}</div>
	  <div class="subMenuList" v-if="!sidebarFold">
		  <div class="subMenuItem" :class="{'active': subMenuIndex == index}"
			@click="setRouter(index,item.routerName)"
			v-for="(item,index) in menuList[menuIndex].list">
			{{item.name}}
		</div>
	  </div>
    </div>
  </aside>
</template>

<script>
  import { isURL } from '@/utils/validate'
  import $common from '@/utils/common.js'
  export default {
    data () {
      return {
        dynamicMenuRoutes: [],
		menuList: [],
      }
    },
	props:{
		menuIndex:{
			type: Number,
			required: true
		},
		subMenuIndex:{
			type: Number,
			required: true
		}
	},
    components: {

    },
	mounted() {
		this.menuList = $common.getItem("menuList");
	},
    computed: {
      sidebarLayoutSkin: {
        get () { return this.$store.state.common.sidebarLayoutSkin }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold }
      },
      menuActiveName: {
        get () { return this.$store.state.common.menuActiveName },
        set (val) { this.$store.commit('common/updateMenuActiveName', val) }
      },
      mainTabs: {
        get () { return this.$store.state.common.mainTabs },
        set (val) { this.$store.commit('common/updateMainTabs', val) }
      },
      mainTabsActiveName: {
        get () { return this.$store.state.common.mainTabsActiveName },
        set (val) { this.$store.commit('common/updateMainTabsActiveName', val) }
      }
    },
    watch: {
      $route: 'routeHandle'
    },
    created () {
      this.menuList = JSON.parse(sessionStorage.getItem('menuList') || '[]')
      this.dynamicMenuRoutes = JSON.parse(sessionStorage.getItem('dynamicMenuRoutes') || '[]')
		// if (this.$store.state.common.navBarSubjectList.length ===0){
		// 	let subMenuIdx = this.getSubjectMenuIndex()
		// 	this.$emit("setSubMenuIndex",subMenuIdx);
		// }
      this.routeHandle(this.$route)
    },
    methods: {
		setRouter(index,routerName){
			// if (routerName != '/subjectList' && this.$store.state.common.navBarSubjectList.length ===0){
			// 	this.$router.push("/subjectList");
			// 	this.$emit("setSelNavbarIndex",'0');
			// 	let subMenuIdx = this.getSubjectMenuIndex()
			// 	this.$emit("setSubMenuIndex",subMenuIdx);
			// } else {
				this.$router.push(routerName);
				this.$emit("setSubMenuIndex",index);
			// }
		},
		getSubjectMenuIndex(){
			let rtn = 0
			this.menuList[0].list.forEach(function(item,idx){
				if (item.routerName === '/subjectList'){
					rtn = idx
				}
			})
			return rtn
		},
    // 路由操作
    routeHandle (route) {
        let sessionFlg = $common.getItem('sessionFlg');
        if (sessionFlg != null && sessionFlg != undefined && sessionFlg) {
            this.$emit("setSubMenuIndex", $common.getItem('subMenuIndex'));
            sessionStorage.setItem('sessionFlg', false)
        }
        if (route.meta.isTab) {
          // tab选中, 不存在先添加
          var tab = this.mainTabs.filter(item => item.name === route.name)[0]
          if (!tab) {
            if (route.meta.isDynamic) {
              route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0]
              if (!route) {
                return console.error('未能找到可用标签页!')
              }
            }
            tab = {
              menuId: route.meta.menuId || route.name,
              name: route.name,
              title: route.meta.title,
              type: isURL(route.meta.iframeUrl) ? 'iframe' : 'module',
              iframeUrl: route.meta.iframeUrl || '',
              params: route.params,
              query: route.query
            }
            this.mainTabs = this.mainTabs.concat(tab)
          }
          this.menuActiveName = tab.menuId + ''
          this.mainTabsActiveName = tab.name
        }
      }
    }
  }
</script>
<style lang="scss" scoped="scoped">
	.subMenuList{

	}
	.subMenuItem{
		height: 40px;
    width: 200px;
		line-height: 40px;
		font-size: 16px;
		color: #1c1c1c;
		padding: 0 20px;
		position: relative;
		cursor: pointer;
    background-color: #FFFFFF;
		&:hover{
			background-color: #036EC2;
      color: #FFFFFF;
		}
		&.active{
			background-color: #036EC2;
			position: relative;
      color: #FFFFFF;
			&::before{
				content: "";
				position: absolute;
				height: 100%;
				width: 5px;
				background-color: #036EC2;
				left: 0;
				top: 0;
			}
		}
	}
</style>
