<template>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中">
    <template v-if="!loading">
      <main-navbar @setSelNavbarIndex="setSelNavbarIndex" @setMenuIndex="setMenuIndex" :menuIndex="menuIndex"/>
      <main-sidebar @setSubMenuIndex="setSubMenuIndex"  :menuIndex="menuIndex" :subMenuIndex="subMenuIndex"/>
      <div class="site-content__wrapper" :style="{ 'min-height': documentClientHeight + 'px' }">
        <main-content @setMenuAllIndex="setMenuAllIndex" v-if="!$store.state.common.contentIsNeedRefresh" />
      </div>
    </template>
  </div>
</template>

<script>
  import MainNavbar from './main-navbar'
  import MainSidebar from './main-sidebar'
  import MainContent from './main-content'
  import $common from '@/utils/common.js'
  export default {
    provide () {
      return {
        // 刷新
        refresh () {
          this.$store.commit('common/updateContentIsNeedRefresh', true)
          this.$nextTick(() => {
            this.$store.commit('common/updateContentIsNeedRefresh', false)
          })
        }
      }
    },
    data () {
      return {
        loading: true,
		menuIndex: $common.getItem("menuIndex"),
		subMenuIndex: $common.getItem("subMenuIndex"),
		menuDataList:[]
      }
    },
    components: {
      MainNavbar,
      MainSidebar,
      MainContent
    },
    computed: {
      documentClientHeight: {
        get () { return this.$store.state.common.documentClientHeight },
        set (val) { this.$store.commit('common/updateDocumentClientHeight', val) }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold }
      },
      userId: {
        get () { return this.$store.state.user.id },
        set (val) { this.$store.commit('user/updateId', val) }
      },
      userName: {
        get () { return this.$store.state.user.name },
        set (val) { this.$store.commit('user/updateName', val) }
      }
    },
    created () {
      this.getUserInfo()
      //this.getSubjectDropDown()
	  this.loading = false
    },
    mounted () {
      this.resetDocumentClientHeight()
    },
    methods: {
      getSubjectDropDown(){
        this.$http({
          url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
          method: "get",
          params: {},
        }).then(({ data }) => {
          if (data && data.resultCode === 200) {
            this.$store.commit('common/updateSubjectList', data.body)
            if (!data.body){
              // console.log("setSubMenuIndex")
              this.$emit("setSubMenuIndex",this.getSubjectMenuIndex());
            } else {
              if($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined){
                $common.setItem("subjectNo", data.body[0].displayValue);
                this.$store.commit('common/updateSelectSubjectNo', data.body[0].displayValue)
              }else{
                let isExist = false;
                data.body.forEach(function (item,idx) {
                  if (item.displayValue === $common.getItem("subjectNo")){
                    isExist = true;
                  }
                });
                if (!isExist){
                  $common.setItem("subjectNo", this.subjectList[0].displayValue);
                  this.$store.commit('common/updateSelectSubjectNo', data.body[0].displayValue)
                }
              }
            }
          }
        });
      },
      // 重置窗口可视高度
      resetDocumentClientHeight () {
        this.documentClientHeight = document.documentElement['clientHeight']
        window.onresize = () => {
          this.documentClientHeight = document.documentElement['clientHeight']
        }
      },
      // 获取当前管理员信息
      getUserInfo () {
        this.$http({
          url: this.$store.state.httpUrl + '/sys/user/info',
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.loading = false
            this.userId = data.user.userId
            this.userName = data.user.username
            $common.setItem("userName",this.userName);
          }
        })
      },
	  setSelNavbarIndex: function(index){
		  this.$set(this.$data,"menuIndex",index);
		  this.$set(this.$data,"subMenuIndex",0);
		  $common.setItem("menuIndex",index);
		  $common.setItem("subMenuIndex",0);
	  },
    setMenuIndex: function(index){
		  this.$set(this.$data,"menuIndex",index);
      $common.setItem("menuIndex",index);
	  },
	  setSubMenuIndex: function(index){
		  this.$set(this.$data,"subMenuIndex",index);
		  $common.setItem("subMenuIndex",index);
	  },
	  setMenuAllIndex: function(menuIndex,subMenuIndex){
		  this.$set(this.$data,"menuIndex",menuIndex);
		  $common.setItem("menuIndex",menuIndex);
		  this.$set(this.$data,"subMenuIndex",subMenuIndex);
		  $common.setItem("subMenuIndex",subMenuIndex);
	  }
    }
  }
</script>
